import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import appSettings from '../../../config/settings';
import configDefault from '../../../config/configDefault';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { required } from '../../../util/validators';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  // displayPrice,
} from '../../../util/configHelpers';

import { Form, PrimaryButton, H3, FieldCurrencyInput } from '../../../components';
import DeliveryMethod from './DeliveryMethod';

import css from './OfferPriceForm.module.css';

const renderForm = formRenderProps => {
  // FormRenderProps from final-form
  const { formId, className, rootClassName, handleSubmit, intl } = formRenderProps;
  const t = intl.formatMessage;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <Form id={formId} onSubmit={handleSubmit} className={classes}>
      <div className={css.modalHeading}>
        <H3 className={css.heading}>{t({ id: 'OfferPriceForm.title' })}</H3>
      </div>

      <DeliveryMethod {...formRenderProps} />

      <FieldCurrencyInput
        id={`${formId}.price`}
        name="price"
        className={css.priceField}
        label={t({ id: 'OfferPriceForm.price' })}
        placeholder={t({
          id: 'OfferPriceForm.pricePlaceholder',
        })}
        currencyConfig={appSettings.getCurrencyFormatting(configDefault.currency)}
        validate={required(t({ id: 'OfferPriceForm.priceRequired' }))}
      />

      <div className={css.submitButton}>
        <PrimaryButton type="submit">
          <FormattedMessage id="OfferPriceForm.ctaButton" />
        </PrimaryButton>
      </div>
    </Form>
  );
};

const OfferPriceForm = props => {
  const intl = useIntl();
  const { listing, config } = props;

  // eslint-disable-next-line no-unused-vars
  const { listingType, unitType, transactionProcessAlias = '', pickupEnabled, shippingEnabled } =
    listing?.attributes?.publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const displayDeliveryMethod = displayPickup || displayShipping;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (displayDeliveryMethod && !pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="OfferPriceForm.noDeliveryMethodSet" />
      </p>
    );
  }
  const deliveryMethod =
    shippingEnabled && !pickupEnabled
      ? 'shipping'
      : !shippingEnabled && pickupEnabled
      ? 'pickup'
      : null;
  const initialValues = { deliveryMethod };

  return <FinalForm {...props} initialValues={initialValues} intl={intl} render={renderForm} />;
};

OfferPriceForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  pickupEnabled: false,
  shippingEnabled: false,
  displayDeliveryMethod: false,
};

OfferPriceForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  pickupEnabled: bool,
  shippingEnabled: bool,
  displayDeliveryMethod: bool,
};

export default OfferPriceForm;
