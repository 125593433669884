import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { H4, IconSpinner, ListingCard } from '../../components';
import { useListListingsQuery } from '../../ducks/ListingsApi';

import css from './ListingPage.module.css';

function getRandomSubarray(arr, size) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, size);
}

const SectionSimilarListings = ({ currentListing }) => {
  const { categoryLevel1, categoryLevel2, categoryLevel3 } =
    currentListing?.attributes?.publicData || {};
  const { data: resData = {}, isLoading } = useListListingsQuery(
    {
      pub_categoryLevel1: categoryLevel1,
      pub_categoryLevel2: categoryLevel2,
      pub_categoryLevel3: categoryLevel3,
      page: 1,
      perPage: 100,
    },
    { skip: !categoryLevel1 && !categoryLevel2 }
  );

  const { listings = [] } = resData;

  const getRandomListings = useMemo(() => {
    const filterListings = listings.filter(l => l?.id?.uuid !== currentListing?.id?.uuid);
    return filterListings.length > 3 ? getRandomSubarray(filterListings, 4) : filterListings;
  }, [listings, currentListing]);

  if (!isLoading && getRandomListings.length === 0) {
    return null;
  }

  return (
    <div className={css.listingsContainer}>
      <H4 as="h2" className={css.listingsTitle}>
        <FormattedMessage id="ListingPage.similarListingTitle" />
      </H4>
      {isLoading ? (
        <IconSpinner />
      ) : (
        <ul className={css.listings}>
          {getRandomListings.map(l => (
            <li className={css.listing} key={l.id.uuid}>
              <ListingCard listing={l} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SectionSimilarListings;
