import React from 'react';

import { required } from '../../../util/validators';
import { useIntl } from '../../../util/reactIntl';
import { useConfiguration } from '../../../context/configurationContext';
import { displayDeliveryPickup, displayDeliveryShipping } from '../../../util/configHelpers';
import { FieldSelect, FieldTextInput, H3 } from '../../../components';

import css from './DeliveryMethod.module.css';

const DeliveryMethod = props => {
  const intl = useIntl();
  const config = useConfiguration();
  const t = intl.formatMessage;
  const { listing, form, formId } = props;

  const currentStock = listing.currentStock?.attributes?.quantity;
  const { listingType, pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const displayDeliveryMethod = displayPickup || displayShipping;

  const hasStock = currentStock && currentStock > 0;
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;

  const showDeliveryMethodSelector = displayDeliveryMethod && hasMultipleDeliveryMethods;
  const deliveryMethod = form.getState().values.deliveryMethod;
  const showSingleDeliveryMethod = displayDeliveryMethod && deliveryMethod;

  return !hasStock ? null : showDeliveryMethodSelector ? (
    <FieldSelect
      id={`${formId}.deliveryMethod`}
      className={css.deliveryField}
      name="deliveryMethod"
      label={t({ id: 'OfferPriceForm.deliveryMethodLabel' })}
      validate={required(t({ id: 'OfferPriceForm.deliveryMethodRequired' }))}
    >
      <option disabled value="">
        {t({ id: 'OfferPriceForm.selectDeliveryMethodOption' })}
      </option>
      <option value={'pickup'}>{t({ id: 'OfferPriceForm.pickupOption' })}</option>
      <option value={'shipping'}>{t({ id: 'OfferPriceForm.shippingOption' })}</option>
    </FieldSelect>
  ) : showSingleDeliveryMethod ? (
    <div className={css.deliveryField}>
      <H3 rootClassName={css.singleDeliveryMethodLabel}>
        {t({ id: 'OfferPriceForm.deliveryMethodLabel' })}
      </H3>
      <p className={css.singleDeliveryMethodSelected}>
        {deliveryMethod === 'shipping'
          ? t({ id: 'OfferPriceForm.shippingOption' })
          : t({ id: 'OfferPriceForm.pickupOption' })}
      </p>
      <FieldTextInput
        id={`${formId}.deliveryMethod`}
        className={css.deliveryField}
        name="deliveryMethod"
        type="hidden"
      />
    </div>
  ) : (
    <FieldTextInput
      id={`${formId}.deliveryMethod`}
      className={css.deliveryField}
      name="deliveryMethod"
      type="hidden"
    />
  );
};

export default DeliveryMethod;
